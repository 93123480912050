<template>
  <div>
    <footer class="py-3 bg-white" style="color: rgb(158, 158, 158);">
      <div class="container px-5 text-center small-footer">
        <p class="m-0">&copy; 2024. HOWCOST INC. All rights reserved.</p>
        <span class="m-0">대표자: 유현오</span>
        <span class="ml-2">이메일: estimate@howcost.co.kr</span>
        <span class="ml-2">전화: 031-417-0466</span>
        <span class="ml-2">사업자번호: 809-86-00250</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ServiceFooter',
  data() {
    return {

    }
  },
}
</script>
