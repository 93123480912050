<template>
  <div id="app" :class="onePageParentClass">
    <Header/>
    <transition name="fade">
      <router-view id="content" :class="onePageContentClass" class="content-main"></router-view>
    </transition>
    <Footer id="footer" :class="onePageFooterClass"/>
  </div>
</template>
  
<script>
import Header from '../MainLayout/header.vue';
import Footer from '../MainLayout/serviceFooter.vue';

export default {
  name: 'ServiceContainer',
  components: {
    Header,
    Footer
  },
  data(){
    return {
      onePagePaths: ['/service/register', '/service/login'],
      onePageParentClass: '',
      onePageContentClass: '',
      onePageFooterClass: '',
    }
  },
  created() {
    // 단순하게 해당 경로로 요청이 들어왔을 때 아래 class를 각 DOM에 추가하는 로직
    // const path = this.$route.path;
    // this.onePagePaths.forEach(url => {
    //   if (path === url) {
    //     this.onePageParentClass = 'one-page-parent';
    //     this.onePageContentClass = 'one-page-content';
    //     this.onePageFooterClass = 'one-page-footer';
    //   }
    // });
    
  },
  methods: {

  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 전체 화면 높이를 기준 */
}

#content {
  flex: 1; /* 컨텐츠 영역을 가변적으로 채워줌 */
}

#footer {
  background-color: #f1f1f1;
  text-align: center;
}
</style>
